import React, { Component,useState, useEffect } from 'react';
import * as DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';

import { useParams } from "react-router-dom";
import MenuItems from './MenuItems';
import getBaseUrl from '../../base/htttp';

function Header1() {    
	const [data, setData] = useState([]);
    const pageSlug = useParams();
	const getData = () => {
		fetch(getBaseUrl()+"menu",{method:'POST'})        
			.then((response) => response.json())
			.then((result) => {
				setData(result.data);
			});            
	}

	useEffect(() => {
		getData();        
	}, []);

	return (        
        <nav id="mobile-menu">
            <ul>                                
                {data.map((menu, index) => {
                    return <MenuItems items={menu} key={index} />;
                })}                                
            </ul>
        </nav>
	);
}

export default Header1;