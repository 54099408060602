import Dropdown from './Dropdown';
const MenuItems = ({ items }) => {
    return (
      <li className="has-sub">
        {items.has_child==1 ? (
          <>
                <a href={items.url} target={items.target}>
                    {items.title}
                </a>
                <Dropdown submenus={items.childreen} />                            
          </>
        ) : (          
          <a href={items.url} target={items.target}>{items.title}</a>
        )}
      </li>
    );
  };
  
  export default MenuItems;